import React from "react";
import styled from "styled-components";
import Heading from "../../../Heading";
import CaseStudiesCard from "../CaseStudiesCard";

const BlogContent = styled.div`
  max-width: 75rem;
  margin: var(--spacing-medium) auto;
  p,
  ul,
  ol {
    font-size: var(--font-size-heading-x-small);
    max-width: 68ch;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: var(--spacing-large);
`;

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border none;
`;

const Introduction = (
  <CaseStudiesCard id="typography">
    <BlogContent>
      <Heading variant="larger" maxWidth="30ch" marginBottom="larger">
        Typography on the web
      </Heading>

      <p>
        This workshop provides an introduction to the history of typography
        through letterpress and how we can use this foundation to inform our
        decisions when creating a typographic vertical rhythm with the use of a
        baseline grid.
      </p>

      <p>
        Embed these techniques within your foundational CSS file, which will be
        within your websites, web-apps or preferably a Design System.
      </p>

      <p>
        By setting these decisions with the foundational CSS file, it will
        ensure there is a well-balanced and rhythmic experience across the
        entire product range.
      </p>

      <VideoWrapper>
        <Video
          width="560"
          height="315"
          src="https://www.youtube.com/embed/FFGSy0EfA9Y"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </VideoWrapper>
    </BlogContent>
  </CaseStudiesCard>
);

export default Introduction;
